import React from 'react'
import './blog.scss'
import {FcChargeBattery} from 'react-icons/fc'

function SingleBlog({imgURL, title, date, category, blogURL}) {
  return (
        <div className="row ">
          <a href={`#${blogURL}`} target='_blank' className='txt-dark'>
            <div className="col-md-2">
                <img src={imgURL} className='me-5' alt="" />
            </div>
            <div className="col-md-4">
                <h4>{title}</h4>
              <div className="row">
                <div className="text-side">
                <div className="col-4">
                  <p>{date}</p>
                </div>
              <div className="col-5 d-flex">
                  <FcChargeBattery />
                  <p>{category}</p>
              </div>
            </div>
            </div>
          </div>
          </a>
    </div>
  )
}

export default SingleBlog