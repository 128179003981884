const Blog = [
    {
        imgURL : 'https://pubs.rsc.org/en/Content/Image/GA/C6CS00776G',
        title: 'Nedir Bu Tuzlu Su Bataryaları?',
        date: '14.04.2022',
        category: 'Battery Tech.',
        blogURL: 'https://www.medium.com'
        
    },
    {
        imgURL : 'https://media-exp1.licdn.com/dms/image/C5622AQGIMOBiPcol8A/feedshare-shrink_800/0/1630059737441?e=2147483647&v=beta&t=qGXdwYV0CO-aMaLXky9TSk58Aj1ylYLjv2pP7s6s7tI',
        title: 'Salty Enerjim Sensinde!',
        date: '14.04.2022',
        category: 'Salty Updts.',
        blogURL: 'https://www.google.com'
    },
    {
        imgURL : 'https://pubs.rsc.org/en/Content/Image/GA/C6CS00776G',
        title: 'What are Salt Water Batteries',
        date: '14.04.2022',
        category: 'Battery Tech.',
        blogURL: 'https://www.youtube.com'
    },
    {
        imgURL : 'https://media-exp1.licdn.com/dms/image/C5622AQGIMOBiPcol8A/feedshare-shrink_800/0/1630059737441?e=2147483647&v=beta&t=qGXdwYV0CO-aMaLXky9TSk58Aj1ylYLjv2pP7s6s7tI',
        title: 'Durum Güncellemesi',
        date: '14.04.2022',
        category: 'Salty Updts.',
        blogURL: 'https://www.twitch.com'
    },
]

export default Blog