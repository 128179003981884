import './App.scss';
import Main from './components/Main/Main';
import Tech from './components/Tech/Tech';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Navbar from './components/Navbar/Navbar';

import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <div className='app'>
      <Navbar />
      <div className='sections'>
        <Main />
        <Services />
        <Tech />
        <About />
        <Contact />
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
