import React from 'react'
import './main.scss'

function Main() {
  return (
    <div className='main'>
      
    </div>
  )
}

export default Main