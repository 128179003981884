import React from 'react'
import {useState} from 'react'
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
import './faq.css'
import {Accordion} from 'react-bootstrap'

function SingleQuestion({title,answer, id}) {
    const [toggle, setToggle] = useState(false)

    const onClick = e => {
        e.preventDefault()
        setToggle(!toggle)
    }

    console.log(toggle)

  return (
<Accordion className='mb-2' defaultActiveKey={id}>
  <Accordion.Item eventKey={id}>
    <Accordion.Header>{title}</Accordion.Header>
    <Accordion.Body>
        {answer}
    </Accordion.Body>
  </Accordion.Item>
</Accordion>
    
  )
}

export default SingleQuestion