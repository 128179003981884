import {useState} from 'react'
import './services.scss'
import Modal from '../Modal/Modal'

function Services() {

  return (
    <div className='services'>
      <div className='container align-items-center justify-content-center'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className="col-10 justify-content-center align-items-center ">
            <h1 className='text-center p-3'>The Real Potential Is In This <br /> Package!</h1>
            <div className='underline'></div>
            <p className='text-center mt-3'>With the SWAB bars that come out of the box, simply take your sample and send it <br/> back to us.</p>
            <Modal />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services