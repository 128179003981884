import {useState, useEffect} from 'react'
import './contact.scss'
import {toast} from 'react-toastify'
import emailjs, { send } from 'emailjs-com'

function Contact() {  
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    message: ''
  });

  const sendEmail = e => {
      e.preventDefault()
      emailjs.sendForm('service_0ktd56r', 'template_94xqlh4', e.target ,'AZevImhkQGzYDIRKx')
      .then(res =>{
        console.log(res)
      }).catch(err => console.log(err))
  }

  return (
    <div className='contact'>
      <div className="container">
        <form onSubmit={sendEmail}>
          <h3>GET IN TOUCH</h3>
          <input 
          type="text" 
          id="name" 
          name='name'
          placeholder='Your Name'
          required
          />
          <input 
          type="email" 
          id="email"
          name='user_email'
          placeholder='Email id'
          required 
          />
          <textarea 
          id='message'
          name='message'
          rows='4'
          placeholder='How can we help you?'
          ></textarea>
          <button type='submit' className='btn btn-primary'>Send</button>
        </form>
      </div>
    </div>
  )
}

export default Contact