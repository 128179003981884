import './about.scss'
import FAQ from '../../data/FAQ'
import Blog from '../../data/Blog'
import SingleQuestion from './FAQ/SingleQuestion'
import SingleBlog from './Blog/SingleBlog'


function About() {
console.log(FAQ)
  return (
    <div className='about d-flex'>
        <div className="container d-flex justify-content-center align-items-center">
            <div className="row ">
                <div className="col-12">
                    <h1>About Salty</h1>
                    <div className="underline"></div>
                    <p className='text-center mb-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur sint nam harum accusamus nobis modi.</p>
                </div>
                <div className="col-md-6 col-sm-12">
                    <h3>FAQ</h3>
                {
                    FAQ?.map((item, idx) => (
                        <SingleQuestion key={idx} {...item} />
                    ))
                }
                </div>
                <div className="col-md-6 col-sm-12">
                    <h3>Blog</h3>
                    {
                        Blog?.map((item, i) => (
                            <SingleBlog key={i} {...item} />
                        ))
                    }
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default About