const FAQ = [
    {
        id: 0,
        title: 'What is Salty?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lacinia venenatis odio ut tincidunt. Ut aliquet cursus sem, id bibendum leo venenatis at. Nunc egestas ante ligula, sit amet dignissim diam posuere vel. Ut efficitur sit amet nulla et malesuada. Sed eget sagittis odio, vel ornare est. Curabitur blandit sed ligula euismod aliquet. Proin fringilla lectus venenatis iaculis convallis. Vestibulum tellus ligula, euismod semper laoreet non, tristique eget mi. Nam venenatis, nunc vitae vestibulum consequat, ex lectus cursus augue, eu lacinia ex libero a nisi. Donec quis enim interdum, iaculis ante eget, accumsan dui.        '
    },
    {
        id: 1,
        title: 'Why Salty?',
        answer: 'Suspendisse et scelerisque dolor. Fusce tempor auctor nulla eu hendrerit. Praesent sem massa, condimentum sit amet facilisis a, pretium sed eros. Fusce hendrerit est vel velit consectetur consectetur. In sed ipsum ac metus feugiat posuere. Curabitur porttitor nulla sem, ut iaculis tellus ullamcorper sed. Duis a lectus a turpis consectetur scelerisque. Nam volutpat ut sapien tempus feugiat. Praesent ac sagittis leo. Integer id dui et nunc bibendum convallis vitae quis sapien. Aliquam erat volutpat.        '
    },
    {
        id: 2,
        title: 'Which areas are suitable for Salty',
        answer: 'Phasellus feugiat lacus eget augue venenatis, sed viverra arcu vulputate. Phasellus ac nunc sit amet turpis ullamcorper tincidunt. Phasellus in laoreet magna. Etiam tempor nulla diam, et scelerisque ipsum ullamcorper at. Sed maximus semper turpis a malesuada. Maecenas id tortor sagittis, euismod sem vel, faucibus turpis. Cras felis lectus, convallis sit amet pellentesque a, volutpat nec nisl. Sed quis nisi in erat venenatis maximus. Sed ligula libero, varius nec sem sed, vulputate consectetur nulla.        '
    },
    {
        id: 3,
        title: 'Why Salty?',
        answer: 'Suspendisse et scelerisque dolor. Fusce tempor auctor nulla eu hendrerit. Praesent sem massa, condimentum sit amet facilisis a, pretium sed eros. Fusce hendrerit est vel velit consectetur consectetur. In sed ipsum ac metus feugiat posuere. Curabitur porttitor nulla sem, ut iaculis tellus ullamcorper sed. Duis a lectus a turpis consectetur scelerisque. Nam volutpat ut sapien tempus feugiat. Praesent ac sagittis leo. Integer id dui et nunc bibendum convallis vitae quis sapien. Aliquam erat volutpat.        '
    },
    {
        id: 4,
        title: 'Why Salty?',
        answer: 'Suspendisse et scelerisque dolor. Fusce tempor auctor nulla eu hendrerit. Praesent sem massa, condimentum sit amet facilisis a, pretium sed eros. Fusce hendrerit est vel velit consectetur consectetur. In sed ipsum ac metus feugiat posuere. Curabitur porttitor nulla sem, ut iaculis tellus ullamcorper sed. Duis a lectus a turpis consectetur scelerisque. Nam volutpat ut sapien tempus feugiat. Praesent ac sagittis leo. Integer id dui et nunc bibendum convallis vitae quis sapien. Aliquam erat volutpat.        '
    },
    {
        id: 4,
        title: 'Why Salty?',
        answer: 'Suspendisse et scelerisque dolor. Fusce tempor auctor nulla eu hendrerit. Praesent sem massa, condimentum sit amet facilisis a, pretium sed eros. Fusce hendrerit est vel velit consectetur consectetur. In sed ipsum ac metus feugiat posuere. Curabitur porttitor nulla sem, ut iaculis tellus ullamcorper sed. Duis a lectus a turpis consectetur scelerisque. Nam volutpat ut sapien tempus feugiat. Praesent ac sagittis leo. Integer id dui et nunc bibendum convallis vitae quis sapien. Aliquam erat volutpat.        '
    },
]

export default FAQ