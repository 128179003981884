import React from 'react'
import './tech.scss'
import techFirst from '../../assets/b1.png'
import techSecond from '../../assets/b2.png'
import techThird from '../../assets/b3.png'
import techFourth from '../../assets/b4.png'


function Tech() {
  return (
    <div className='tech'>
      <div className="container align-items-center">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-6 ">
            <h2>Teknolojimiz</h2>
            <p>Özel anot teknolojisi ile Salty, piyasadaki diğer pillere göre 3 kat daha uzun kullanım ömrüne ve% 95 verimlilikle en az 5000 döngüye sahiptir.</p> 
            <p>Prototipimizi sıfırdan geliştiriyoruz. Şu an anot ve katodu sentezlemeye çalışıyoruz.</p>
            <div className="alazea-progress-bar mb-50">
              <div className="single_progress_bar">
                  <p>Genel ilerleme</p>
                  <div id="bar4" className="barfillerK">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill" ></span>
                  </div>
              </div>
            </div>

            <div className="alazea-progress-bar mb-50">
              <div className="single_progress_bar">
                  <p>Anot</p>
                  <h6>Anot üretimi tamamlanmış olup, satışa hazırdır.</h6>
                  <div id="bar4" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill" ></span>
                  </div>
              </div>
            </div>
            <div className="alazea-progress-bar mb-50">
              <div className="single_progress_bar">
                  <p>Katot</p>
                  <h6>Katot üretimi tamamlanma aşamasındadır.</h6>
                  <div id="bar4" className="barfillerK">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill" ></span>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="row mt-5">
              <div className="col-md-6">
                <img src={techFirst} />
                <h5 className='mt-3'>Uzun Kullanım Ömrü</h5>
                <p>Salty % 80 DoD ile yaklaşık 5000 döngü ömür sağlar.</p>
              </div>
              <div className="col-md-6">
                <img src={techSecond} />
                <h5 className='mt-3'>Güvenli Kullanım</h5>
                <p>Hiçbir patlama riski yok.</p>
              </div>
              <div className="col-md-6">
                <img src={techThird} />
                <h5 className='mt-3'>Tasarruf</h5>
                <p>Bol miktarda Sodyum sayesinde, akülerimiz diğer akü teknolojilerinden çok daha ekonomiktir.</p>
              </div>
              <div className="col-md-6">
                <img src={techFourth} />
                <h5 className='mt-3'>Çevre Dostu</h5>
                <p className='mb-4'>Çevreye zararlı bir etkisi yoktur.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tech